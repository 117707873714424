import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        name: "LogIn",
        component: () => import('./components/views/login/Log_In.vue'),
        path: '/login/:url',
        props: true
    },
    {
        name: 'ManageDomains',
        path: '/manage/domains',
        component: () => import('./components/views/domains/manage/ManageDomain.vue')
    },
    {
        name: 'AddDomain',
        path: '/add/domain',
        component: () => import('./components/views/domains/add/AddDomain.vue')
    },
    {
        name: "Home_Page",
        component: () => import('./components/views/domains/manage/ManageDomain.vue'),
        path: "/",
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
})
export default router;